//* Import the functions you need from the SDKs you need
import { initializeApp, getApps } from 'firebase/app';
import { getAuth, setPersistence, browserLocalPersistence } from 'firebase/auth';

//* Add the Web App's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyBMmvmQzC3R0WtCr0Y1Titr52x0SrVZ2Ao",
  authDomain: "x-bird.firebaseapp.com",
  projectId: "x-bird",
  storageBucket: "x-bird.appspot.com",
  messagingSenderId: "600447636059",
  appId: "1:600447636059:web:3c7a7e731316f76d33e57e",
  measurementId: "G-L5N48H3TKP",
};

//* Initialize Firebase
let firebase_app = getApps().length === 0 ? initializeApp(firebaseConfig) : getApps()[0];

//* Initialize Firebase Auth and set persistence
const auth = getAuth(firebase_app);
setPersistence(auth, browserLocalPersistence)
  .then(() => {
    console.log("Session persistence set to LOCAL");
  })
  .catch((error) => {
    console.error("Failed to set session persistence:", error);
  });

export { auth };
export default firebase_app;
