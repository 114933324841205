import React from "react";
import { NavLink, useLocation } from "react-router-dom";




const Footer = () => {
  const location = useLocation();

  const footerLinks = [
    {
      title: "Runway",
      link: "/",
      icon: <img id="reels2" alt='XBird' src='/xbird-home.png' className={location.pathname === "/" ? "w-[24px] h-[24px] brightness-[1]" : "w-[24px] h-[24px]"} />
    },
    {
      title: "Earn",
      link: "/earn",
      icon: <img alt='XBird' src='/xbird-earns.png' className={location.pathname === "/earn" ? "w-[24px] h-[24px] brightness-[1]" : "w-[24px] h-[24px]"} />
    },
    {
      title: "Invite",
      link: "/ref",
      icon: <img alt='XBird' src='/xbird-earn.png' className={location.pathname === "/ref" ? "w-[24px] h-[24px] brightness-[1]" : "w-[24px] h-[24px]"} />
    },

    {
      title: "Rankings",
      link: "/leaderboard",
      icon: <img alt='XBird' src='/x-bird-leaders.png' className={location.pathname === "/leaderboard" ? "w-[24px] h-[24px] brightness-[1]" : "w-[24px] h-[24px]"} />
    },


  ]

  return (
    <div className="w-full flex z-30 relative items-center justify-center space-x-2">

      {footerLinks.map((footer, index) => (
        <NavLink
          key={index}
          to={footer.link}
          className={({ isActive }) => {
            return `

${isActive
                ? "w-[25%] flex flex-col rounded-[10px] items-center justify-center text-primary text-[13px]"
                : "w-[25%] flex flex-col space-y-[2px] rounded-[10px] items-center justify-center text-[13px]"
              }
  `;
          }}
        >
          <span id="reels" className={location.pathname === `${footer.link}` ?
            `w-[60px] h-[24px] bg-btn mb-[6px] flex flex-col rounded-[24px] items-center justify-center text-[13px]`
            : "w-[60px] h-[24px] flex flex-col space-y-[2px] rounded-[10px] items-center justify-center text-primary text-[13px]"}>
            {footer.icon}
          </span>
          <span className="font-medium">{footer.title}</span>
        </NavLink>
      ))}


    </div>
  );
};

export default Footer;
